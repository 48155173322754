import Tribute from 'tributejs/dist/tribute';

import { json, status } from '../../fetchUtils';
import idempotence from '../../idempotence';
import nodeListToArray from '../../nodeListToArray';
import ready from '../../ready';

const addMentionsButton = {
  init() {
    nodeListToArray(document.querySelectorAll('[data-js-perform="add-mentions"]'))
      .forEach((button) => {
        this.initItem(button);
      });
  },

  initItem(button) {
    if (idempotence.guard(button, 'add-mentions-button')) return;

    const input = document.getElementById(button.dataset.jsTarget);

    const tribute = new Tribute({
      fillAttr: 'slug',
      lookup: 'name',
      menuContainer: document.getElementById(`${button.dataset.jsTarget}-tribute-container`),
      positionMenu: false,
      values: (text, cb) => {
        fetch(`/members/search?q=${text}`, { method: 'GET' })
          .then(status)
          .then(json)
          .then((response) => {
            cb(response.members);
          });
      }
    });

    tribute.attach(input);

    button.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (input.value.slice(-1) !== ' ' && input.value !== '') {
        input.value += ' ';
      }
      input.focus();
      setTimeout(() => {
        tribute.showMenuForCollection(input);
      }, 100);
    });
  }
};

ready(() => {
  addMentionsButton.init();
});

export default addMentionsButton;
