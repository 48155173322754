import { json, status } from '../../fetchUtils';
import idempotence from '../../idempotence';
import nodeListToArray from '../../nodeListToArray';
import ready from '../../ready';

const memberSearchFormLoader = {
  init() {
    nodeListToArray(document.querySelectorAll('[data-js-load-member-search-form]'))
      .forEach((loader) => {
        if (!idempotence.guard(loader, 'load-member-search-form')) {
          const url = new URL('/members/load_search_form', window.location.origin);
          url.search = window.location.search;
          fetch(url.toString(), { method: 'GET' })
            .then(status)
            .then(json)
            .then((response) => {
              loader.innerHTML = response.component;
              FontAwesome.dom.i2svg(); // replace the icon tags with svgs
              // initialize all the stuff that appears in the form
              window.Alumnifire.collapse.init();
              window.Alumnifire.placesAutocomplete.init();
              window.Alumnifire.searchableSelect.init();
            });
        }
      });
  }
};

ready(() => {
  memberSearchFormLoader.init();
});

export default memberSearchFormLoader;
