import Awesomplete from 'awesomplete/awesomplete';
import { json, status } from '../../fetchUtils';
import idempotence from '../../idempotence';
import nodeListToArray from '../../nodeListToArray';
import ready from '../../ready';

const selectWithAjaxOptions = {
  init() {
    nodeListToArray(document.querySelectorAll('[data-js-select-with-ajax-options]'))
      .forEach((input) => {
        this.initItem(input);
      });
  },

  initItem(input) {
    if (idempotence.guard(input, 'select-with-ajax-options')) return;

    let minchars = input.dataset.minchars;
    if (minchars === undefined) minchars = 1;
    const awesomplete = new Awesomplete(input, {
      autoFirst: false,
      maxItems: 99999,
      minChars: minchars,
      sort: false
    });

    const datalistUrl = input.dataset.jsSelectWithAjaxOptions;
    if (datalistUrl && datalistUrl.length > 0) {
      input.addEventListener('input', () => {
        const q = input.value;
        if (q.length >= minchars) {
          fetch(`${datalistUrl}?q=${encodeURIComponent(q)}`, { method: 'GET' })
            .then(status)
            .then(json)
            .then((response) => {
              awesomplete.list = response;
              awesomplete.evaluate();
            });
        } else {
          awesomplete.list = [];
        }
      });
    }
  }
};

ready(() => {
  selectWithAjaxOptions.init();
});

export default selectWithAjaxOptions;
