import loadGoogleMapsApi from 'load-google-maps-api';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

import idempotence from '../idempotence';
import ready from '../ready';

const memberMap = {
  init() {
    const self = this;
    const mapCanvas = document.getElementById('member-map');
    let markerData;
    let zoomBox;
    if (mapCanvas) {
      if (idempotence.guard(mapCanvas, 'member-map')) return;
      markerData = mapCanvas.dataset.jsMapMarkers;
      zoomBox = mapCanvas.dataset.jsZoomBox;

      loadGoogleMapsApi({
        key: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
        libraries: ['geometry'],
        v: 3
      }).then((mapsApi) => {
        if (typeof markerData !== 'undefined') {
          self.draw(mapsApi, JSON.parse(markerData), mapCanvas, zoomBox); // eslint-disable-line no-undef
        }
      });
      document.addEventListener('shown.bs.collapse', (event) => {
        if (event.target.getAttribute('id') === 'map-wrapper') {
          const formField = document.querySelector('[data-js-toggle="map-form-field"]');
          if (formField) formField.value = '1';
        }
      });
      document.addEventListener('hidden.bs.collapse', (event) => {
        if (event.target.getAttribute('id') === 'map-wrapper') {
          const formField = document.querySelector('[data-js-toggle="map-form-field"]');
          if (formField) formField.value = '0';
        }
      });
    }
  },

  draw(mapsApi, markersData, mapElement, zoomBox) {
    const self = this;
    const map = new mapsApi.Map(mapElement, {
      fullscreenControl: true,
      mapTypeControl: false,
      rotateControl: false,
      scaleControl: true,
      streetViewControl: false,
      styles: this.styles,
      zoomControl: true
    });

    const bounds = new mapsApi.LatLngBounds();

    const markers = [];
    markersData.forEach((location) => {
      const locationName = location[2];
      const latLng = new mapsApi.LatLng(location[0], location[1]);
      if (typeof zoomBox === 'undefined') {
        bounds.extend(latLng);
      }
      for (let i = 0; i < location[3]; ++i) {
        const marker = new mapsApi.Marker({
          position: latLng
        });
        marker.addListener('click', () => {
          self.markerClick(mapsApi, marker, locationName);
        });
        markers.push(marker);
      }
    });

    if (zoomBox) {
      JSON.parse(zoomBox).forEach((loc) => {
        const latLng = new mapsApi.LatLng(loc[0], loc[1]);
        bounds.extend(latLng);
      });
    }

    new MarkerClusterer({ map, markers }); // eslint-disable-line no-new

    map.fitBounds(bounds);
  },

  markerClick(mapsApi, marker, locationName) {
    marker.setAnimation(mapsApi.Animation.BOUNCE);
    const url = new URL(window.location);
    url.searchParams.set('location', locationName);
    url.searchParams.set('show_map', '1');
    window.location = url.toString();
  },

  styles: [
    {
      featureType: 'landscape.natural',
      elementType: 'labels.text',
      stylers: [{ visibility: 'on' }, { color: '#ff0000' }]
    },
    {
      featureType: 'landscape.natural',
      elementType: 'all',
      stylers: [{ visibility: 'on' }, { color: '#ffffffa' }]
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{ color: '#cfddce' }]
    },
    {
      featureType: 'road',
      elementType: 'all',
      stylers: [{ saturation: -100 }, { lightness: 45 }, { visibility: 'on' }]
    },
    { featureType: 'road.highway', elementType: 'all', stylers: [{ visibility: 'on' }] },
    { featureType: 'road.highway', elementType: 'labels', stylers: [{ visibility: 'off' }] },
    { featureType: 'road.arterial', elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
    { featureType: 'transit', elementType: 'all', stylers: [{ visibility: 'on' }] },
    { featureType: 'water', elementType: 'all', stylers: [{ color: '#94a2af' }, { visibility: 'on' }] }
  ]
};

ready(() => {
  memberMap.init();
});

export default memberMap;
