import idempotence from '../../idempotence';
import nodeListToArray from '../../nodeListToArray';
import ready from '../../ready';

const eventLocation = {
  init() {
    nodeListToArray(document.querySelectorAll('[data-js-toggle="event-location"]'))
      .forEach((fieldset) => {
        this.initFieldset(fieldset);
      });
  },

  initFieldset(fieldset) {
    const self = this;
    if (idempotence.guard(fieldset, 'event-location')) return;

    const onlineToggle = fieldset.querySelector('input[type="checkbox"]');
    const locationField = fieldset.querySelector('input[name="event[location]"]');
    const locationLabel = fieldset.querySelector('[data-bs-toggle="collapse"]');
    let previousChoice = '';

    if (onlineToggle.checked) {
      locationField.setAttribute('readonly', true);
      locationField.value = 'Online';
      locationField.setAttribute('value', 'Online');
    }

    const locationLabelSuffix = document.createElement('span');
    locationLabel.appendChild(locationLabelSuffix);
    self.updateLocationLabel(locationField, locationLabelSuffix);

    locationLabel.addEventListener('click', () => {
      self.updateLocationLabel(locationField, locationLabelSuffix);
    });

    onlineToggle.addEventListener('change', () => {
      const mapboxField = fieldset.querySelector('.mapboxgl-ctrl-geocoder--input');
      if (onlineToggle.checked) {
        previousChoice = locationField.value;
        locationField.value = 'Online';
        locationField.setAttribute('value', 'Online');
        locationField.setAttribute('readonly', true);
        mapboxField.value = 'Online';
        mapboxField.setAttribute('value', 'Online');
        mapboxField.setAttribute('readonly', true);
      } else {
        locationField.value = previousChoice;
        locationField.setAttribute('value', previousChoice);
        locationField.removeAttribute('readonly');
        mapboxField.value = previousChoice;
        mapboxField.setAttribute('value', previousChoice);
        mapboxField.removeAttribute('readonly');
      }
    });
  },

  updateLocationLabel(field, holder) {
    if (field.value.length > 0) {
      holder.textContent = `- ${field.value}`;
    } else {
      holder.textContent = '';
    }
  }
};

ready(() => {
  eventLocation.init();
});

export default eventLocation;
