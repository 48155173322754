import idempotence from '../idempotence';
import nodeListToArray from '../nodeListToArray';
import ready from '../ready';

const tabs = {
  init() {
    nodeListToArray(document.querySelectorAll('[data-js-tabs-push-state]'))
      .forEach((tablist) => {
        if (!idempotence.guard(tablist, 'tabs-push-state')) {
          nodeListToArray(tablist.querySelectorAll('.nav-link'))
            .forEach((link) => {
              link.addEventListener('shown.bs.tab', () => {
                const sectionName = link.getAttribute('aria-controls');
                const url = new URL(window.location);
                url.searchParams.set('filter', sectionName);
                window.history.pushState({}, '', url.toString());
              });
            });
        }
      });
  }
};

ready(() => {
  tabs.init();
});

export default tabs;
