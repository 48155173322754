import idempotence from '../idempotence';
import ready from '../ready';

import authenticityToken from './forms/authenticityToken';

const alertDismiss = {
  init() {
    this.getAlerts().forEach((alert) => {
      if (!idempotence.guard(alert, 'dismiss')) {
        alert.addEventListener('closed.bs.alert', () => {
          const url = new URL(`/alerts/${alert.dataset.alertDismiss}/dismiss`, window.location.origin);
          const data = new FormData();
          const token = authenticityToken();
          if (token) data.append('authenticity_token', token);
          fetch(url.toString(), {
            body: data,
            headers: { Accept: 'application/json' },
            method: 'POST'
          });
        });
      }
    });
  },

  getAlerts() {
    return [].slice.call(document.querySelectorAll('[data-alert-dismiss]'));
  },
};

ready(() => {
  alertDismiss.init();
});

export default alertDismiss;
