import { json, status } from '../../fetchUtils';
import idempotence from '../../idempotence';
import nodeListToArray from '../../nodeListToArray';
import ready from '../../ready';

const postCommentsComponentLoader = {
  init() {
    nodeListToArray(document.querySelectorAll('[data-js-load-post-comments-component]'))
      .forEach((loader) => {
        if (!idempotence.guard(loader, 'load-post-comments-component')) {
          const path = `/posts/${loader.dataset.jsLoadPostCommentsComponent}/load_comments_component`;
          const url = new URL(path, window.location.origin);
          url.searchParams.set('expanded', loader.dataset.jsExpanded);
          fetch(url.toString(), { method: 'GET' })
            .then(status)
            .then(json)
            .then((response) => {
              loader.innerHTML = response.component;
              FontAwesome.dom.i2svg(); // replace the icon tags with svgs
              window.jdenticon(); // init the jdenticons for comment avatars
            });
        }
      });
  }
};

ready(() => {
  postCommentsComponentLoader.init();
});

export default postCommentsComponentLoader;
