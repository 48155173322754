import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

import idempotence from '../../idempotence';
import nodeListToArray from '../../nodeListToArray';
import ready from '../../ready';

const placesAutocomplete = {
  init() {
    nodeListToArray(document.querySelectorAll('[data-js-places-autocomplete]'))
      .forEach((autocompleteField) => {
        this.initItem(autocompleteField);
      });
    nodeListToArray(document.querySelectorAll('[data-js-multi-places-autocomplete]'))
      .forEach((autocompleteContainer) => {
        this.initMultiItem(autocompleteContainer);
      });
  },

  createGeocoder(customOptions) {
    return new MapboxGeocoder({
      accessToken: import.meta.env.VITE_MAPBOX_TOKEN,
      autocomplete: true,
      fuzzyMatch: true,
      mode: 'mapbox.places',
      types: 'locality,neighborhood,place',
      worldview: 'us',
      ...customOptions
    });
  },

  // the mapbox geocoder hijacks behavior when using the enter key in a form
  // this code hijacks it back and submits the form as expected
  reinitializeFormBehavior(form) {
    form.addEventListener('keypress', (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        if (document.activeElement.type === 'textarea') {
          document.activeElement.value += '\n';
        } else {
          form.querySelector('[type="submit"]').click();
        }
      }
    });
  },

  initItem(textInput) {
    if (idempotence.guard(textInput, 'places-autocomplete')) return;

    const types = ['locality', 'neighborhood', 'place'];
    if (textInput.dataset.jsPlacesAutocomplete === 'address') {
      types.push('address');
    }
    const customGeocoderOptions = { types: types.join(' ') };
    if (textInput.placeholder.length > 0) {
      customGeocoderOptions.placeholder = textInput.placeholder;
    }
    textInput.classList.add('d-none');
    const geocoder = this.createGeocoder(customGeocoderOptions);
    geocoder.addTo(textInput.parentElement);

    // init the geocoder input with whatever is in the "real" input on initialization
    if (textInput.value.length > 0) {
      geocoder._inputEl.value = textInput.value;
    }

    // the "real" input needs to track the value as it changes in the geocoder input
    geocoder._inputEl.addEventListener('change', () => {
      textInput.value = geocoder._inputEl.value;
      textInput.setAttribute('value', geocoder._inputEl.value);
    });

    const containingForm = textInput.form;
    if (containingForm) this.reinitializeFormBehavior(containingForm);
  },

  /*
   * To handle adding multiple places using the same input field, we use a text field input to generate
   * the autocomplete behavior. Upon selecting an autocomplete location, or hitting the enter key, we
   * 1) grab the autocomplete value from the text field, 2) add it to a hidden select field that will be
   * used for form submission, and 3) display the value as a tag in a tag container for UX purposes.
  */
  initMultiItem(container) {
    const self = this;
    if (idempotence.guard(container, 'multi-places-autocomplete')) return;
    const autocompleteContainer = container.querySelector('.places-autocomplete');
    const textInput = container.querySelector('input[type="text"]');
    const hiddenSelect = container.getElementsByTagName('select')[0];
    const tagContainer = container.querySelector('[data-js-tag-container]');
    const customGeocoderOptions = {};
    if (textInput.placeholder.length > 0) {
      customGeocoderOptions.placeholder = textInput.placeholder;
    }
    textInput.classList.add('d-none');
    const geocoder = this.createGeocoder(customGeocoderOptions);
    geocoder.addTo(autocompleteContainer);

    nodeListToArray(tagContainer.querySelectorAll('.tag')).forEach((tag) => {
      self.initRemoveTag(tag, hiddenSelect);
    });

    geocoder.on('result', () => {
      const place = geocoder._inputEl.value;
      const tag = tagContainer.querySelector('[data-js-clonable-tag]');
      const newTag = tag.cloneNode(true);
      const newOption = document.createElement('option');
      newOption.selected = true;
      newOption.value = place;
      newOption.textContent = place;
      hiddenSelect.appendChild(newOption);
      newTag.classList.remove('d-none');
      newTag.setAttribute('data-js-clonable-tag', null);
      newTag.querySelector('.value').textContent = place;
      tagContainer.appendChild(newTag);
      self.initRemoveTag(newTag, hiddenSelect);
      geocoder.clear();
    });

    const containingForm = textInput.form;
    if (containingForm) this.reinitializeFormBehavior(containingForm);
  },

  initRemoveTag(tag, hiddenSelect) {
    // don't initialize the template tag until it's been cloned
    if (tag.dataset.jsClonableTag && tag.dataset.jsClonableTag !== 'null') return;
    if (idempotence.guard(tag, 'places-autocomplete-remove-tag')) return;

    tag.querySelector('[data-js-remove-tag]').addEventListener('click', (e) => {
      e.stopPropagation();
      e.preventDefault();
      const selectOption = hiddenSelect.querySelector(
        `option[value="${tag.querySelector('.value').textContent.trim()}"]`
      );
      selectOption.parentNode.removeChild(selectOption);
      tag.parentNode.removeChild(tag);
    });
  }
};

ready(() => {
  placesAutocomplete.init();
});

export default placesAutocomplete;
