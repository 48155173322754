import { json, status } from '../../fetchUtils';
import idempotence from '../../idempotence';
import nodeListToArray from '../../nodeListToArray';
import ready from '../../ready';

const reactionTogglesComponentLoader = {
  init() {
    nodeListToArray(document.querySelectorAll('[data-js-load-reaction-toggles-component]'))
      .forEach((loader) => {
        if (!idempotence.guard(loader, 'load-reaction-toggles-component')) {
          const path = `/posts/${loader.dataset.jsLoadReactionTogglesComponent}/load_reaction_toggles_component`;
          const url = new URL(path, window.location.origin);
          fetch(url.toString(), { method: 'GET' })
            .then(status)
            .then(json)
            .then((response) => {
              loader.innerHTML = response.component;
              FontAwesome.dom.i2svg(); // replace the icon tags with svgs
            });
        }
      });
  }
};

ready(() => {
  reactionTogglesComponentLoader.init();
});

export default reactionTogglesComponentLoader;
