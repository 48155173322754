import { Modal } from 'bootstrap';

import { json, status } from '../fetchUtils';
import idempotence from '../idempotence';
import ready from '../ready';

import placesAutocomplete from './forms/placesAutocomplete';
import searchableSelect from './forms/searchableSelect';

const modal = {
  init() {
    document.querySelectorAll('.modal').forEach((bsModal) => {
      if (!idempotence.guard(bsModal, 'modal')) {
        if (bsModal.getAttribute('data-js-display-immediately')) {
          (new Modal(bsModal)).show();
        }

        bsModal.addEventListener('shown.bs.modal', () => {
          const input = bsModal.querySelector('input:not([type="hidden"]');
          if (input) input.focus();
        });

        bsModal.addEventListener('show.bs.modal', () => {
          const contentUrl = bsModal.getAttribute('data-js-content-url');
          if (contentUrl) {
            const body = bsModal.querySelector('.modal-body');

            fetch(contentUrl, { method: 'GET' })
              .then(status)
              .then(json)
              .then((response) => {
                body.innerHTML = response.content;
                searchableSelect.init();
                placesAutocomplete.init();
              });
          }
        });
      }
    });
  }
};

ready(() => {
  modal.init();
});

export default modal;
