import Awesomplete from 'awesomplete/awesomplete';

import idempotence from '../../idempotence';
import nodeListToArray from '../../nodeListToArray';
import ready from '../../ready';

const searchableSelect = {
  init() {
    nodeListToArray(document.querySelectorAll('[data-js-searchable-select]'))
      .forEach((select) => {
        this.initSelect(select);
      });
  },

  initSelect(select) {
    if (idempotence.guard(select, 'searchable-select')) return false;

    // generate the options and placeholder from the select input
    const list = [];
    let placeholder = '';
    nodeListToArray(select.children).forEach((element) => {
      const option = { label: element.textContent, value: element.value };
      if (option.value.length > 0) {
        list.push(option);
      } else {
        placeholder = option.label;
      }
    });

    // create the awesomplete
    const awesompleteInput = this.initAwesompleteInput(select, placeholder);
    const awesomplete = this.initAwesomplete(awesompleteInput, select, list);

    // hide the original select
    select.setAttribute('tabindex', -1);
    select.classList.add('d-none', 'invisible');

    const options = (select.dataset.jsSearchableSelectOptions || '').split(' ');

    if (options.includes('allowcreate')) {
      /*
       * if the awesomplete should allow the creation of options, we will add a placeholder option
       * to the end of the list with a unique value we can use to identify the created option when
       * selected
       */
      const creatorValue = '%!|%!|%!|';
      const creatorLabelPrefix = 'Add ';
      const creatorLabelSuffix = '...';
      const creatorOption = { label: creatorLabelPrefix + creatorLabelSuffix, value: creatorValue };
      list.push(creatorOption);
      // ensure the creator is included as a match regardless of the user's input
      awesomplete.filter = (text, input) => {
        const isMatch = Awesomplete.FILTER_CONTAINS(text, input);
        const isCreator = text.includes(creatorLabelPrefix) && text.includes(creatorLabelSuffix);
        return isMatch || isCreator;
      };
      // update the display of the creator to include the user's input
      ['change', 'keyup', 'paste', 'cut'].forEach((eventName) => {
        awesompleteInput.addEventListener(eventName, (e) => {
          const entry = e.target.value;
          const listItems = nodeListToArray(awesomplete.ul.childNodes);
          const lastEntry = listItems.slice(-1)[0];
          if (lastEntry) lastEntry.textContent = `${creatorLabelPrefix}${entry}${creatorLabelSuffix}`;
        });
      });
      awesompleteInput.addEventListener('awesomplete-select', (e) => {
        const value = (e.text || e.originalEvent.text).value;
        if (value === creatorValue) {
          this.createNewOption(e, list, select);
        }
      });
      // if you are tabbing out, assume that you want to select whatever you've highlighted
      awesompleteInput.addEventListener('keydown', (e) => {
        if (e.key === 'Tab') {
          awesomplete.select();
        }
      });
    } else {
      awesompleteInput.addEventListener('focusout', () => {
        const entry = awesompleteInput.value;
        if (!this.listIncludes(list, entry)) {
          awesompleteInput.value = null;
        }
        // if only one option is available and you've typed at least one character, choose it
        if (awesomplete.ul.childNodes.length === 1 && entry.length > 0) {
          this.selectNextOption(awesomplete);
        }
      });
    }

    if (select.multiple) {
      const previousSelections = select.querySelectorAll('option[selected="selected"]');
      if (previousSelections) {
        nodeListToArray(previousSelections).forEach((option) => {
          const idx = this.listIndexOf(list, option.value);
          this.addToPillbox(awesompleteInput, list[idx], list, select);
        });
      }
      const separators = select.dataset.separators || '';
      if (separators.length > 0) {
        awesompleteInput.addEventListener('input', (e) => {
          e.preventDefault();
          e.stopPropagation();
          const value = awesompleteInput.value;
          let lastSeparatorLocation = 0;
          for (let i = 0; i < value.length; i++) {
            if (separators.includes(value[i])) {
              if (i > lastSeparatorLocation) {
                const item = value.substr(lastSeparatorLocation, i - lastSeparatorLocation);
                awesompleteInput.value = item;
                this.selectNextOption(awesomplete);
              }
              lastSeparatorLocation = i + 1;
            }
          }
          const remainingText = value.substr(lastSeparatorLocation);
          awesompleteInput.value = remainingText;
        });
      }
      let isOpen = false;
      awesompleteInput.addEventListener('awesomplete-open', () => {
        isOpen = true;
      });
      awesompleteInput.addEventListener('awesomplete-close', () => {
        isOpen = false;
      });
      awesompleteInput.addEventListener('click', () => {
        if (!isOpen) awesomplete.open();
      });
      awesompleteInput.addEventListener('awesomplete-selectcomplete', (e) => {
        const selectedOption = { label: e.text.label, value: e.text.value };
        this.addToPillbox(awesompleteInput, selectedOption, list, select);
        const status = `Selected ${selectedOption.label}`;
        document.querySelector(`[data-js-pillbox-status="${awesompleteInput.getAttribute('aria-owns')}"]`).textContent = status;
        // for some reason when you remove items from the list, you have to reinstantiate awesomplete
        awesomplete.list = list;
        awesomplete.close();
        FontAwesome.dom.i2svg();
      });
    } else {
      const selection = select.querySelector('option[selected="selected"]');
      if (selection) {
        awesompleteInput.value = selection.textContent;
      }
      awesompleteInput.addEventListener('change keyup paste cut', (e) => {
        const entry = e.target.value;
        if (!this.listIncludes(list, entry)) {
          select.value = null;
          select.getElementsByTagName('option').selected = false;
        }
      });
      awesompleteInput.addEventListener('awesomplete-selectcomplete', (e) => {
        const value = (e.text || e.originalEvent.text).value;
        select.value = value;
        const searchVal = value.toString().replace(/"/g, '\\"');
        select.querySelector(`option[value="${searchVal}"]`).selected = true;
      });
      awesompleteInput.addEventListener('focusout', () => {
        if (awesompleteInput.value.trim().length === 0) {
          this.clearSelections(select);
        }
      });
    }

    // if original select is required, make the awesomplete input required
    // this must be last so the event handler runs after the select has already received selections
    if (select.getAttribute('required') === 'required') {
      this.checkValidity(awesompleteInput, select);
      awesompleteInput.addEventListener('awesomplete-selectcomplete', () => {
        this.checkValidity(awesompleteInput, select);
      });
    }

    return awesompleteInput;
  },

  initAwesompleteInput(select, placeholder) {
    const awesompleteInput = document.createElement('input');
    awesompleteInput.setAttribute('type', 'text');
    awesompleteInput.classList.add(...select.getAttribute('class').split(' '));
    awesompleteInput.setAttribute('aria-label', select.getAttribute('aria-label'));
    awesompleteInput.setAttribute('placeholder', placeholder);
    const id = select.id;
    if (id) {
      awesompleteInput.setAttribute('id', `${id}_awesompleted`);
      const label = document.querySelector(`label[for="${id}"]`);
      if (label) {
        label.setAttribute('for', `${id}_awesompleted`);
      }
    }
    select.parentNode.append(awesompleteInput);
    return awesompleteInput;
  },

  initAwesomplete(awesompleteInput, select, list) {
    let minchars = select.dataset.minchars;
    if (minchars === undefined) minchars = 1;
    const awesomplete = new Awesomplete(awesompleteInput, {
      autoFirst: false,
      minChars: minchars,
      maxItems: 99999,
      sort: false,
      replace: this.inputLabel,
      list
    });
    // expand the select options on focus or a down arrow press if no characters are required
    if (minchars < 1) {
      awesompleteInput.addEventListener('focus', () => {
        awesomplete.evaluate();
      });
      awesompleteInput.addEventListener('keydown', (e) => {
        if (e.keyCode === 40 && !awesomplete.isOpened) awesomplete.open();
      });
    }
    return awesomplete;
  },

  selectNextOption(awesomplete) {
    awesomplete.next();
    awesomplete.select();
  },

  inputLabel(suggestion) {
    this.input.value = suggestion.label;
  },

  listIncludes(list, test) {
    return list.map((e) => e.label).includes(test);
  },

  listIndexOf(list, value) {
    for (let i = 0; i < list.length; i++) {
      if (list[i].value === value) return i;
    }
    return -1;
  },

  listRemoveValue(list, value) {
    const index = this.listIndexOf(list, value);
    list.splice(index, 1);
  },

  listInsertOption(list, option) {
    for (let i = 0; i < list.length; i++) {
      if (list[i].label > option.label) {
        list.splice(i, 0, option);
        return list;
      }
    }
    list.splice(-1, 0, option);
    return list;
  },

  createNewOption(e, list, select) {
    const realValue = e.target.value;
    const permanentOption = { label: realValue, value: realValue };
    this.listInsertOption(list, permanentOption);
    (e.text || e.originalEvent.text).label = realValue;
    (e.text || e.originalEvent.text).value = realValue;

    const option = document.createElement('option');
    option.value = realValue;
    option.textContent = realValue;
    select.append(option);
  },

  addToPillbox(input, option, list, select) {
    const searchVal = option.value.toString().replace(/"/g, '\\"');
    select.querySelector(`option[value="${searchVal}"]`).selected = true;
    input.value = null;
    this.listRemoveValue(list, option.value);

    let pillbox = document.querySelector(`[data-js-pillbox="${input.getAttribute('aria-owns')}"]`);
    if (!pillbox) {
      pillbox = this.initPillbox(input);
    }
    const pillboxItem = document.createElement('span');
    pillboxItem.classList.add(
      'bg-primary',
      'border',
      'border-white',
      'd-inline-block',
      'fs-7',
      'me-3',
      'mt-1',
      'position-relative',
      'px-2',
      'py-1',
      'rounded',
      'text-white',
      'text-nowrap'
    );
    pillboxItem.setAttribute('data-label', option.label);
    pillboxItem.setAttribute('data-value', option.value);
    pillbox.append(pillboxItem);
    this.initPillboxItemContent(pillboxItem, option.label);
    this.initPillboxItemRemoveLink(input, pillboxItem, list, select);
  },

  initPillbox(input) {
    const pillbox = document.createElement('div');
    pillbox.classList.add('bg-light', 'form-control', 'mt-1', 'pillbox');
    pillbox.setAttribute('data-js-pillbox', input.getAttribute('aria-owns'));
    const statusAlert = document.createElement('span');
    statusAlert.classList.add('visually-hidden');
    statusAlert.setAttribute('role', 'status');
    statusAlert.setAttribute('hidden', true);
    statusAlert.setAttribute('data-js-pillbox-status', input.getAttribute('aria-owns'));
    statusAlert.setAttribute('aria-live', 'assertive');
    statusAlert.setAttribute('aria-atomic', true);
    pillbox.prepend(statusAlert);
    input.parentNode.append(pillbox);
    return pillbox;
  },

  initPillboxItemContent(pillboxItem, textContent) {
    const content = document.createElement('span');
    content.classList.add('d-inline-block');
    content.textContent = textContent;
    pillboxItem.prepend(content);
  },

  initPillboxItemRemoveLink(input, pillboxItem, list, select) {
    const pillboxItemRemoveLinkWrapper = document.createElement('div');
    pillboxItemRemoveLinkWrapper.classList.add('position-absolute', 'start-100', 'top-0', 'translate-middle', 'z-1');
    const pillboxItemRemoveLink = document.createElement('a');
    const label = pillboxItem.dataset.label;
    pillboxItemRemoveLink.classList.add('link-light', 'text-decoration-none');
    pillboxItemRemoveLink.setAttribute('aria-label', `Click to de-select ${label}`);
    pillboxItemRemoveLink.setAttribute('data-label', label);
    pillboxItemRemoveLink.setAttribute('data-value', pillboxItem.dataset.value);
    pillboxItemRemoveLink.setAttribute('role', 'button');
    pillboxItemRemoveLink.setAttribute('tabindex', 0);
    const iconWrapper = document.createElement('div');
    iconWrapper.classList.add('bg-danger', 'border', 'border-white', 'p-2', 'position-relative', 'rounded-circle');
    const icon = document.createElement('i');
    icon.classList.add('far', 'fa-times', 'fs-8', 'position-absolute', 'text-white', 'translate-middle');
    iconWrapper.appendChild(icon);
    pillboxItemRemoveLink.appendChild(iconWrapper);
    pillboxItemRemoveLinkWrapper.appendChild(pillboxItemRemoveLink);
    pillboxItemRemoveLink.addEventListener('click', (e) => {
      this.removePillboxItem(input, e, list, select);
    });
    pillboxItem.append(pillboxItemRemoveLinkWrapper);
  },

  removePillboxItem(input, e, list, select) {
    const pillboxItemRemoveLink = e.currentTarget;
    const pillboxItemRemoveLinkWrapper = pillboxItemRemoveLink.parentNode;
    const pillboxItem = pillboxItemRemoveLinkWrapper.parentNode;
    const pillbox = pillboxItem.parentNode;

    const option = {
      label: pillboxItem.dataset.label,
      value: pillboxItem.dataset.value
    };
    pillbox.removeChild(pillboxItem);
    // the pillbox will contain a status childNode when it's empty, hence testing for length > 2
    if (pillbox.childNodes.length < 2) {
      pillbox.parentNode.removeChild(pillbox);
    }
    this.listInsertOption(list, option);
    const searchVal = option.value.toString().replace(/"/g, '\\"');
    select.querySelector(`option[value="${searchVal}"]`).selected = false;
    pillbox.querySelector('[data-js-pillbox-status]').textContent = `De-selected ${option.label}`;
    if (select.getAttribute('required') === 'required') {
      this.checkValidity(input, select);
    }
  },

  checkValidity(input, select) {
    if (select.selectedOptions.length === 0) {
      input.setCustomValidity('Please fill out this field.');
    } else {
      input.setCustomValidity('');
    }
  },

  clearSelections(select) {
    nodeListToArray(select.querySelectorAll('option')).forEach((option) => {
      option.selected = false;
    });
  }
};

ready(() => {
  searchableSelect.init();
});

export default searchableSelect;
