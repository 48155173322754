const redirect = {
  to(destination) {
    if (destination.match(/^http/)) {
      window.location = destination;
    } else {
      window.location = window.location.origin + destination;
    }
  }
};

export default redirect;
