import idempotence from '../../idempotence';
import nodeListToArray from '../../nodeListToArray';
import ready from '../../ready';

const fileUploader = {
  init() {
    nodeListToArray(document.querySelectorAll('[data-js-file-uploader]'))
      .forEach((uploader) => {
        this.initInput(uploader);
      });
  },

  initInput(uploader) {
    const input = uploader.querySelector('input[type="file"]');
    if (idempotence.guard(input, 'file-uploader-file-input')) return;

    input.addEventListener('change', () => {
      const textField = this.getTextField(uploader);
      const files = input.files;
      let message = '';
      if (files) {
        if (files.length > 1) {
          message = `${files.length} files selected`;
        } else if (files.length === 1) {
          message = files[0].name;
        }
      }
      if (textField) {
        textField.value = message;
      } else if (message) {
        alert(message);
      }
    });
  },

  textField(uploader) {
    return document.querySelector(`[data-js-file-uploader-text="${uploader.dataset.jsFileUploader}"]`);
  }
};

ready(() => {
  fileUploader.init();
});

export default fileUploader;
