import idempotence from '../idempotence';
import nodeListToArray from '../nodeListToArray';
import ready from '../ready';

const disableWith = {
  init() {
    nodeListToArray(document.querySelectorAll('[data-disable-with]'))
      .forEach((link) => {
        const originalValue = link.dataset.disableWithOriginalValue;
        if (originalValue && originalValue.length > 0) {
          link.innerHTML = originalValue;
        }
        this.initLink(link);
      });
  },

  initLink(link) {
    if (idempotence.guard(link, 'disable-with')) return;

    link.addEventListener('click', (e) => {
      const target = e.currentTarget;
      const originalValue = target.innerHTML;
      if (originalValue !== target.dataset.disableWith) {
        target.setAttribute('data-disable-with-original-value', originalValue);
      }
    });
  }
};

ready(() => {
  disableWith.init();
});

export default disableWith;
