import tippy from 'tippy.js';

import idempotence from '../idempotence';
import nodeListToArray from '../nodeListToArray';
import ready from '../ready';

const tooltip = {
  init() {
    nodeListToArray(document.querySelectorAll('[data-tippy-content]')).forEach((tt) => {
      if (!idempotence.guard(tt, 'tippy')) {
        tippy(tt, { allowHTML: true });
      }
    });
  }
};

ready(() => {
  tooltip.init();
});

export default tooltip;
