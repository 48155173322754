/* Nested Text Field:
 * We use a form element that looks like a text input, but allows for multiple values to be entered when
 * separated by comma, semicolon, or return keypresses. The text input is not actually tied to the form,
 * it simply triggers a hidden nested form component on certain interactions.
 */

import $ from 'jquery';

import idempotence from '../../idempotence';
import nodeListToArray from '../../nodeListToArray';
import ready from '../../ready';

const nestedTextField = {
  init() {
    nodeListToArray(document.querySelectorAll('[data-nested-text-field]'))
      .forEach((field) => {
        this.initField(field);
      });
  },

  initField(field) {
    if (idempotence.guard(field, 'nested-text-field')) return;

    const fieldName = field.dataset.nestedTextField;
    const input = field.querySelector('input[type="text"]');
    const form = input.closest('form');
    const linkToAdd = field.querySelector(`[data-target="#${fieldName}-fields"]`);

    // trigger nested field addition before form submission if there's a value in our mock text input
    form.addEventListener('submit', () => {
      if (input.value !== '') linkToAdd.click();
    });

    // trigger nested field addition when pressing enter, comma, or semicolon
    input.addEventListener('keypress', (e) => {
      const code = e.key;
      if ((code === ',' || code === ';') && input.value !== '') {
        e.preventDefault();
        linkToAdd.click();
      }
    });

    // ugh nested form requires jquery
    $(document).on(`nested:fieldAdded:${fieldName}`, (e) => {
      // get rid of the character that triggered the addition
      const value = input.value.replace(/,|;/g, '');

      // prevent the nested field from being added if the user tried to input a blank value
      if (value === '') {
        e.preventDefault();
        e.stopPropagation();
      }

      // reset the input after moving the value to the nested field
      input.value = '';

      // add the value to the newly added nested field
      e.field.find('.display').text(value);
      e.field.find('.field input').val(value);
    });
  }
};

ready(() => {
  nestedTextField.init();
});

export default nestedTextField;
