import idempotence from '../idempotence';
import nodeListToArray from '../nodeListToArray';
import ready from '../ready';

const inbox = {
  init() {
    nodeListToArray(document.querySelectorAll('[data-js-inbox-item]'))
      .forEach((item) => {
        if (!idempotence.guard(item, 'inbox')) {
          const link = item.querySelector('a');
          item.addEventListener('show.bs.collapse', () => {
            item.classList.add('active');
            item.classList.remove('unread');
            if (link) {
              link.classList.remove('link-secondary');
              link.classList.add('link-light');
            }
          });

          item.addEventListener('hide.bs.collapse', () => {
            item.classList.remove('active');
            if (link) {
              link.classList.remove('link-light');
              link.classList.add('link-secondary');
            }
          });
        }
      });
  }
};

ready(() => {
  inbox.init();
});

export default inbox;
