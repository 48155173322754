import { json, status } from '../../fetchUtils';
import idempotence from '../../idempotence';
import nodeListToArray from '../../nodeListToArray';
import ready from '../../ready';

const componentLoader = {
  init() {
    nodeListToArray(document.querySelectorAll('[data-js-load-component]'))
      .forEach((section) => {
        if (!idempotence.guard(section, 'load-component')) {
          const url = new URL(section.dataset.jsLoadComponent, window.location.origin);
          let params = section.dataset.jsLoadComponentParams;
          if (params) {
            if (typeof params === 'string') {
              params = JSON.parse(params);
            }
            if (typeof params === 'object') {
              Object.keys(params).forEach((key) => {
                url.searchParams.set(key, params[key]);
              });
            }
          }
          fetch(url.toString(), { method: 'GET' })
            .then(status)
            .then(json)
            .then((response) => {
              section.innerHTML = response.component;
              window.jdenticon();
              window.Alumnifire.tooltip.init();
            });
        }
      });
  }
};

ready(() => {
  componentLoader.init();
});

export default componentLoader;
