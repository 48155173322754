/* eslint no-console:0 */
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// import font awesome stuff first so the icons get loaded in right away
import '@fortawesome/fontawesome-pro/js/all';
import '../src/faFixes';

// import all of bootstrap
import { Collapse, Dropdown, Modal } from 'bootstrap';

// or import individual bootstrap modules
// import 'bootstrap/js/dist/dom/data';
// import 'bootstrap/js/dist/dom/event-handler';
// import 'bootstrap/js/dist/dom/manipulator';
// import 'bootstrap/js/dist/dom/selector-engine';
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/base-component';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
// import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

import Rails from '@rails/ujs';
import $ from 'jquery';

import '../src/vendor/jquery-nested-form';

import '../src/ieViewportFix';
import '../src/jdenticon';
import '../src/sentry';

import Alumnifire from '../src/components';

// import this last since it slows other things down
import '../src/initAOS';

// make bootstrap methods availble to our view scripts
Alumnifire.Collapse = Collapse;
Alumnifire.Dropdown = Dropdown;
Alumnifire.Modal = Modal;

window.$ = $;
window.Alumnifire = Alumnifire;

if (!window._rails_loaded) {
  Rails.start();
}
