import idempotence from '../../idempotence';
import nodeListToArray from '../../nodeListToArray';
import ready from '../../ready';

const cancelForm = {
  init() {
    nodeListToArray(document.querySelectorAll('[data-js-cancel-form]')).forEach((link) => {
      this.initItem(link);
    });
  },

  initItem(link) {
    if (idempotence.guard(link, 'cancel-form')) return;

    link.addEventListener('click', (e) => {
      e.preventDefault();

      const intent = confirm('All of your changes will be lost permanently. Are you sure you want to cancel?');
      if (intent) {
        const form = e.currentTarget.closest('form');
        if (form) form.reset();
      }
    });
  }
};

ready(() => {
  cancelForm.init();
});

export default cancelForm;
