import nodeListToArray from '../nodeListToArray';

import alertDismiss from './alertDismiss';
import collapse from './collapse';
import disableWith from './disableWith';
import facebook from './facebook';
import hoverDropdown from './hoverDropdown';
import inbox from './inbox';
import memberMap from './memberMap';
import modal from './modal';
import popover from './popover';
import previewImage from './previewImage';
import tabs from './tabs';
import textToHtml from './textToHtml';
import tooltip from './tooltip';
import unreadBadge from './unreadBadge';

import addEmojiButton from './forms/addEmojiButton';
import addMentionsButton from './forms/addMentionsButton';
import affiliationSearch from './forms/affiliationSearch';
import autogrowTextarea from './forms/autogrowTextarea';
import cancelForm from './forms/cancelForm';
import characterCounter from './forms/characterCounter';
import checkBoxTrigger from './forms/checkBoxTrigger';
import eventLocation from './forms/eventLocation';
import eventTime from './forms/eventTime';
import fakeForm from './forms/fakeForm';
import fileUploader from './forms/fileUploader';
import nestedFileUploader from './forms/nestedFileUploader';
import nestedTextField from './forms/nestedTextField';
import placesAutocomplete from './forms/placesAutocomplete';
import requiredFieldset from './forms/requiredFieldset';
import searchableSelect from './forms/searchableSelect';
import selectTrigger from './forms/selectTrigger';
import selectWithAjaxOptions from './forms/selectWithAjaxOptions';
import serviceSelector from './forms/serviceSelector';

import componentLoader from './loaders/componentLoader';
import memberMapComponentLoader from './loaders/memberMapComponentLoader';
import memberSearchFormLoader from './loaders/memberSearchFormLoader';
import postCommentsComponentLoader from './loaders/postCommentsComponentLoader';
import postDropdownComponentLoader from './loaders/postDropdownComponentLoader';
import postModalLoader from './loaders/postModalLoader';
import reactionTogglesComponentLoader from './loaders/reactionTogglesComponentLoader';

const Alumnifire = {
  addEmojiButton,
  addMentionsButton,
  affiliationSearch,
  alertDismiss,
  autogrowTextarea,
  cancelForm,
  characterCounter,
  checkBoxTrigger,
  collapse,
  componentLoader,
  disableWith,
  eventLocation,
  eventTime,
  facebook,
  fakeForm,
  fileUploader,
  hoverDropdown,
  inbox,
  memberMap,
  memberMapComponentLoader,
  memberSearchFormLoader,
  modal,
  nestedFileUploader,
  nestedTextField,
  nodeListToArray,
  placesAutocomplete,
  popover,
  postCommentsComponentLoader,
  postDropdownComponentLoader,
  postModalLoader,
  previewImage,
  reactionTogglesComponentLoader,
  requiredFieldset,
  searchableSelect,
  selectTrigger,
  selectWithAjaxOptions,
  serviceSelector,
  tabs,
  textToHtml,
  tooltip,
  unreadBadge
};

export default Alumnifire;
