const executeScript = {
  execute(content) {
    const meta = document.querySelector('meta[name=csp-nonce]');
    const cspNonce = meta && meta.content;
    const script = document.createElement('script');
    script.setAttribute('nonce', cspNonce);
    script.text = content;
    document.head.appendChild(script).parentNode.removeChild(script);
  }
};

export default executeScript;
